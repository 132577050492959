<template>
	<div
		class="company__result--card"
		@click.stop="goToCompanyPage(company)"
		:class="cardActive ? 'active' : ''"
	>
		<div class="w-100">
			<div class="company__box--text company__box--header ">
				{{ company.apprenticeship.name }}
			</div>
			<div class=" company__box--text company__box--company mt-10">
				{{ company.name }}
			</div>
			<div class=" mt-10 row__start flex-wrap">
				<div
					class="company__box--information company__box--text mb-5 mr-10"
					v-if="company.city"
				>
					{{ company.city }}
				</div>
				<div
					class="company__box--information company__box--text mb-5 mr-10"
					v-for="(occupation, secondIndex) in company.occupations"
					:key="secondIndex"
				>
					{{ occupation.name }}
				</div>
				<div
					class="company__box--information company__box--text mb-5 mr-10"
					v-if="company.apprenticeship.offer"
				>
					{{ company.apprenticeship.offer.name }}
				</div>
				<div
					class="company__box--information company__box--text mb-5 mr-10"
					v-if="company.year"
				>
					{{ company.year }}
				</div>
			</div>
		</div>

		<div class="company__box--arrow" :class="cardActive ? '' : 'size0'">
			<ArrowRightActiveSvg />
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		name: "HomeCompanyResultCard",
		data() {
			return {};
		},
		props: {
			company: { type: Object, default: () => {} },
		},
		methods: {
			...mapActions([]),
			goToCompanyPage(company) {
				var company_name = company.name.trim().replaceAll(" ", "-");
				var url = "/company/" + company.id + "/" + company_name;
				this.$router.push(url);
			},
			chooseCompany() {
				if (this.selectedCompany.id == this.company.id) {
					this.$router.push({
						name: "Company",
						params: {
							id: this.selectedCompany.id,
							name: this.selectedCompany.name
								.trim()
								.replaceAll(" ", "-"),
						},
					});
				} else {
					let activeCompany = this.availableCompanies.find(
						(comp) => comp.id == this.company.id
					);

					this.$store.commit("setSelectedCompany", activeCompany);
					this.$store.commit(
						"setMapsCenterPoint",
						this.company.allLatLongs[0].latLong
					);
				}
			},
		},
		created() {},
		computed: {
			...mapGetters(["availableCompanies", "selectedCompany"]),
			cardActive() {
				return this.company.id == this.selectedCompany.id;
			},
		},
	};
</script>
<style lang="scss">
	@import "../assets/scss/_variables.scss";
	.company__result--card {
		margin-bottom: 20px;
		background: $white;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: calc(33% - 40px);
		margin-right: 10px;
		padding: 10px 15px;
		border: 1.5px solid $grey__300;
		border-radius: 6px;
		transition: all $transition;
		cursor: pointer;
		box-shadow: 0 3px 6px rgba(#000000, 0.1);
		@media only screen and (max-width: 1330px) {
			width: calc(33% - 45px);
		}
		@media only screen and (max-width: 950px) {
			width: calc(50% - 45px);
		}
		@media only screen and (max-width: 600px) {
			width: calc(90%);
			margin-right: 0px;
		}
		&.active {
			border-color: $primary;
		}

		.company__box--text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			&.company__box--header {
				width: calc(100% - 20px);
				font-size: 15px;
			}
			&.company__box--company {
				max-width: calc(100% - 40px);
				width: fit-content;
				font-size: 13px;
				padding: 5px 10px;
				border-radius: 12px;
				background-color: $primary;
				color: $white;
			}
			&.company__box--information {
				font-size: 13px;
				padding: 5px 10px;
				border-radius: 12px;
				border: 1px solid $grey__700;
			}
		}
		.company__box--arrow {
			align-self: center;
			&.size0 {
				height: 10px;
				width: 10px;
				svg {
					height: 0px;
					width: 0px;
					overflow: hidden;
				}
			}
		}
	}
</style>
