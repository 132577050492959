<template>
	<div>
		<div class="searchbox row__spacebetween">
			<input
				type="text"
				v-model="filter.companyName"
				placeholder="Suche"
				@change="$emit('filterChanged')"
			/>
			<SearchSvg />
		</div>
		<p class="text__primary m-15">oder</p>
		<div class="row__start row__start--wrap">
			<CheckBox
				v-for="district in districtOptions"
				:key="district"
				:text="district.trim()"
				:active="filter.selectedDistricts.includes(district)"
				class="w-50-5 mr-5 mb-10"
				@click="
					() => {
						if (filter.selectedDistricts.includes(district)) {
							filter.selectedDistricts = filter.selectedDistricts.filter(
								(d) => d != district
							);
						} else {
							filter.selectedDistricts.push(district);
						}
					}
				"
			/>
		</div>
		<p
			v-if="$route.query.app == 1"
			class="text__primary company_counter mt-15 mb-20"
		>
			{{ count }} Ergebnisse für deine Suche
		</p>
		<p v-else class="text__primary company_counter mb-50"></p>

		<general-select
			classes="selectbox--big"
			placeholder="Ort"
			:noName="true"
			:options="filteredPlaceOptions"
			:selectedItem="filter.selectedPlace"
			@change="
				(option) => {
					if (filter.selectedPlace == option || option == 'Alle') {
						filter.selectedPlace = null;
					} else {
						filter.selectedPlace = option;
					}
				}
			"
		/>
		<general-select
			class="mt-15"
			classes="selectbox--big"
			placeholder="Ausbildungsberuf"
			:options="apprenticeshipOptions"
			:selectedItem="filter.selectedApprenticeship"
			:readonly="false"
			@change="
				(option) => {
					if (
						filter.selectedApprenticeship.id == option.id ||
						option.id == null
					) {
						filter.selectedApprenticeship = {};
					} else {
						filter.selectedApprenticeship = option;
					}
				}
			"
		/>
		<general-select
			class="mt-15"
			classes="selectbox--big"
			placeholder="Unternehmen"
			:options="companyOptions"
			:selectedItem="filter.selectedCompany"
			@change="
				(option) => {
					if (filter.selectedCompany.id == option.id || option.id == null) {
						filter.selectedCompany = {};
					} else {
						filter.selectedCompany = option;
					}
				}
			"
		/>
		<general-select
			class="mt-15"
			classes="selectbox--big"
			placeholder="Berufsfelder"
			:options="occupationOptions"
			:selectedItem="filter.selectedOccupation"
			@change="
				(option) => {
					if (filter.selectedOccupation.id == option.id || option.id == null) {
						filter.selectedOccupation = {};
					} else {
						filter.selectedOccupation = option;
					}
				}
			"
		/>
		<general-select
			class="mt-15"
			classes="selectbox--big"
			placeholder="Angebot"
			:options="offerOptions"
			:selectedItem="filter.selectedOffer"
			@change="
				(option) => {
					if (filter.selectedOffer.id == option.id || option.id == null) {
						filter.selectedOffer = {};
					} else {
						filter.selectedOffer = option;
					}
				}
			"
		/>
		<div class="row__spacebetween">
			<CheckBox
				v-for="year in years"
				:key="year"
				:text="year"
				:active="year == filter.selectedYear"
				class="w-50-5 mt-15  mb-10"
				@click="
					() => {
						if (filter.selectedYear == year) {
							filter.selectedYear = null;
						} else {
							filter.selectedYear = year;
						}
					}
				"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "HomeFilterCompanies",
	data() {
		return {
			years: [new Date().getFullYear(), new Date().getFullYear() + 1],
		};
	},
	props: ["filter", "count"],
	methods: {},
	created() {},
	computed: {
		filteredPlaceOptions() {
			// Array Districts <string>
			let districts = this.filter.selectedDistricts;
			// Array<{name:string,district:string}>
			let places = ["Alle"];
			if (districts.length == 0) {
				this.availableCities.forEach((element) => {
					places.push(element);
				});
			} else {
				this.placeOptions.forEach((placeObject) => {
					if (
						districts.includes(placeObject.district) ||
						this.filter.selectedPlace == placeObject.name
					) {
						places.push(placeObject.name);
					}
				});
			}
			return places;
		},
		...mapGetters([
			"offerOptions",
			"occupationOptions",
			"districtOptions",
			"placeOptions",
			"companyOptions",
			"apprenticeshipOptions",
			"availableCities",
		]),
	},
};
</script>
