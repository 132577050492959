<template>
	<div class="row__spacebetween w-full relative">
		<img
			class="calendaricons"
			src="../../assets/images/appointmentDateIcons.png"
		/>
		<div class="w-75 calendarbox">
			<date-picker
				is-expanded
				v-model="date"
				:available-dates="dates"
				:locale="{
					id: 'de',
					firstDayOfWeek: 2,
					masks: { weekdays: 'WW.' },
				}"
			/>
		</div>
		<img
			class="max-w-100 mb-50 calendarperson"
			src="../../assets/images/appointmentDatePerson.png"
		/>
	</div>
</template>

<script>
	import DatePicker from "v-calendar/lib/components/date-picker.umd";
	import { mapGetters } from "vuex";
	export default {
		components: { DatePicker },
		name: "AppointmentDate",
		data() {
			return { date: null };
		},
		props: ["selectedDate", "selectedApprenticeship"],
		emits: ["changeDate", "changeWorkshop"],
		methods: {
			makeChangeEvent() {
				if (this.date == null) {
					return null;
				}
				let date = new Date(this.date);
				let month = date.getMonth() + 1;
				if (month.toString().length == 1) {
					month = "0" + month;
				}
				let day = date.getDate();
				if (day.toString().length == 1) {
					day = "0" + day;
				}
				let year = date.getFullYear();
				this.$emit("changeDate", [year, month, day].join("-"));
				var workshop = {};
				this.contact.workshops.forEach((element) => {
					if (
						new Date(element.name).getMonth() == date.getMonth() &&
						new Date(element.name).getYear() == date.getYear() &&
						new Date(element.name).getDate() == date.getDate()
					) {
						workshop = element;
					}
				});
				this.$emit("changeWorkshop", workshop);
			},
		},
		created() {},
		computed: {
			...mapGetters(["companyAppointments"]),

			dates() {
				var dates = [];
				for (const key in this.companyAppointments) {
					// console.log(key);
					dates.push(key);
				}

				return dates;
			},
		},
		watch: {
			date: "makeChangeEvent",
		},
	};
</script>
<style lang="scss">
	@import "../../assets/scss/_variables.scss";
	.relative {
		position: relative;
	}
	.calendaricons {
		position: absolute;
		top: 0px;
		left: 0;
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.calendarperson {
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.calendarbox {
		margin: 60px 0 0 120px;
		border-radius: 10px;
		max-width: 90vw;
		box-shadow: 0 3px 6px rgba(#000000, 0.16);
		.vc-header {
			padding: 30px 50px 15px 50px;
		}
		.vc-title {
			width: 100%;
			text-align: center;
			font-size: 30px;
			font-weight: 600;
			color: #000;
			padding-bottom: 15px;
			border-bottom: 1px solid #efefef;
		}
		.vc-arrows-container {
			padding: 30px 20px 8px 20px;
		}
		.vc-weekday {
			color: #000;
		}
		.vc-day-content {
			color: $primary;
			transition: all 0.3s linear;
		}
		.vc-day-content.is-disabled {
			color: #fff;
			background: #efefef;
			cursor: default;
		}

		.vc-highlight {
			background-color: $primary !important;
		}
		.vc-day-box-center-center {
			padding-bottom: 15px;
		}
		@media only screen and (max-width: 600px) {
			width: 100% !important;
			margin: 10px 0 50px 0;
		}
	}
</style>
