import Axios from "axios";

const state = {
	availableCompanies: [],
	availableCities: [],
	selectedCompany: [],
	selectedCompanyImage: null,
	oneCompany: null,

	companyContacts: [],
	companyChatTypes: [],
	mapsCenterPoint: { lat: 51.267564, lng: 6.530134 },
};

const getters = {
	companyContacts: (state) => state.companyContacts,
	companyChatTypes: (state) => state.companyChatTypes,
	oneCompany: (state) => state.oneCompany,
	availableCompanies: (state) => state.availableCompanies,
	availableCities: (state) => state.availableCities,
	selectedCompany: (state) => state.selectedCompany,
	selectedCompanyImage: (state) => state.selectedCompanyImage,
	mapsCenterPoint: (state) => state.mapsCenterPoint,
};
const mutations = {
	setCompanyContacts: (state, value) => {
		state.companyContacts = value;
	},
	setCompanyChatTypes: (state, value) => {
		state.companyChatTypes = value;
	},
	setAvailableCompanies: (state, value) => {
		state.availableCompanies = value;
	},
	setAvailableCities: (state, value) => {
		var cities = [];
		value.forEach((element) => {
			element.apprenticeships.forEach((apprenticeship) => {
				if (apprenticeship.city != null) {
					if (!cities.includes(apprenticeship.city)) {
						cities.push(apprenticeship.city);
					}
				}
			});
		});
		state.availableCities = cities;
	},
	setSelectedCompany: (state, value) => {
		state.selectedCompany = value;
	},
	setOneCompany: (state, value) => {
		state.oneCompany = value;
	},
	setOneCompanyImages: (state, value) => {
		state.oneCompany.images = value;
	},
	setSelectedCompanyImage: (state, value) => {
		state.selectedCompanyImage = value;
	},
	setMapsCenterPoint: (state, value) => {
		state.mapsCenterPoint = value;
	},
};
const actions = {
	getAvailableCompanies({ commit }, params) {
		Axios.get(window.baselink + "getAvailableCompanies", {
			params,
		}).then(function(response) {
			if (response.status == 200) {
				commit("setAvailableCompanies", response.data.companies);
				commit("setAvailableCities", response.data.companies);
				commit("setPlaceOptions", response.data.options.cities);
				commit("setCompanyOptions", response.data.options.companies);
				commit("setDistrictOptions", response.data.options.districts);
				commit(
					"setOccupationOptions",
					response.data.options.occupations
				);
				commit("setOfferOptions", response.data.options.offers);
				commit(
					"setApprenticeshipOptions",
					response.data.options.apprenticeships
				);
			}
		});
	},
	async getOneCompany({ commit }, { id }) {
		commit("setOneCompany", null);
		await Axios.get(window.baselink + "getCompany/" + id).then(function(
			response
		) {
			if (response.status == 200) {
				commit("setOneCompany", response.data.company);
			}
		});
		if (state.oneCompany && state.oneCompany.images.length > 0) {
			commit("setSelectedCompanyImage", state.oneCompany.images[0]);
		}
	},
};

export default {
	namespace: "companies",
	state,
	getters,
	actions,
	mutations,
};
