<template>
    <div class="footer" style="margin-top:50px;">
        <div class="row__spacebetween">
            <a class="logo--svg"> <LogoSvg /></a>
            <div class="row_end ml-auto">
                <!-- <a class="footer__link cursor-pointer"
                    ><p class="text__grey">Kontakt</p></a
                > -->
                <a
                    @click="
                        $route.name != 'Privacy'
                            ? $router.push({ name: 'Privacy' })
                            : ''
                    "
                    class="footer__link cursor-pointer"
                    ><p class="text__grey">Datenschutz</p></a
                >
                <a
                    @click="
                        $route.name != 'Imprint'
                            ? $router.push({ name: 'Imprint' })
                            : ''
                    "
                    class="footer__link cursor-pointer"
                    ><p class="text__grey">Impressum</p></a
                >
            </div>
        </div>
        <div class="devider"></div>
        <div class="row__spacebetween">
            <p class="text__grey mb-30">info@its-your-match.de</p>
            <p class="text__grey" style="max-width:900px; margin-bottom:10px;">It's your Match ist ein Projekt der Industrie und Handelskammer Mittlerer Niederrhein. Azubi-Speed-Dating.app PRO ist eine Software der opwoco products GmbH</p>
            <p class="text__lightgrey mb-30">© opwoco products GmbH</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Footer",
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
    mounted() {},
};
</script>
<style lang="scss" scoped></style>
