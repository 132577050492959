<template>
    <div class=" w-100 row__center">
        <div class="max-w-700">
            <img
                class="max-w-100 mb-50"
                src="../../assets/images/appointmentDone.png"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: "AppointmentBooked",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>
