<template>
	<div class="navbar row__spacebetween main__padding">
		<div class="row__spacebetween w-100 cursor-pointer">
			<a class="logo--svg" @click="$router.push({ name: 'Home' })">
				<!-- <a class="logo--svg"> -->
				<LogoSvg />
			</a>
			<div class="row__spacebetween navbar--row m-15 for__navbar--big">
				<a
					class="navbar--link cursor-pointer"
					v-for="route in navbarRoutes"
					:key="route.id"
					@click="
						() => {
							route.link != $route.name
								? $router.push({ name: route.link })
								: '';
						}
					"
					>{{ route.name }}</a
				>
			</div>
			<a
				class="contact-button row__center for__navbar--big"
				@click="$router.push({ name: 'Contact' })"
			>
				<p class="text__white small">Kontakt</p>
			</a>
			<div
				class="for__navbar--small pointer"
				@click="navbarActive = !navbarActive"
			>
				<BurgerMenu :active="navbarActive" />
			</div>
		</div>
		<div
			class="navbar__dropdown"
			:class="{ 'navbar__dropdown--active': navbarActive }"
		>
			<a
				v-for="route in navbarRoutes"
				:key="route.id"
				@click="
					() => {
						route.link != $route.name ? $router.push({ name: route.link }) : '';

						navbarActive = false;
					}
				"
				>{{ route.name }}</a
			>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	name: "Navbar",
	data() {
		return {
			navbarActive: false,
			navbarRoutes: [
				{
					id: 1,
					name: "Startseite",
					link: "Home",
				},
				{
					id: 4,
					name: "Teilnehmende Unternehmen",
					link: "ParticipatingCompanies",
				},
				{
					id: 5,
					name: "Für Unternehmen",
					link: "ForCompanies",
				},
				{
					id: 6,
					name: "Für Schüler",
					link: "ForStudents",
				},
			],
		};
	},
	methods: {
		...mapActions([]),
	},
	created() {},
	computed: {},
	mounted() {},
};
</script>
