<template>
    <div id="app" :style="`${$route.query.app == 1 ? 'margin-top: -80px;' : ''}`">
    <Navbar v-if="$route.query.app != 1" />

    <router-view v-if="!isWaiting" />
    <NavbarBottom />
    <NavbarUser v-if="!($route.name == 'Chat' || ($route.query.app == 1 && ($route.name == 'Home' || $route.name == 'ParticipatingCompanies' || $route.name == 'Company')))"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isWaiting: true,
    };
  },
  methods: {
    ...mapActions(["checkUserLogin"]),
  },
  async created() {
    await this.$store.dispatch("setToken");
    await this.$store.dispatch("checkLogin");
    this.isWaiting = false;
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
  watch: {
    $route() {
      if (this.$route.query.app == 1) {
        if (localStorage.getItem("app") == null) {
          localStorage.setItem("app", 1);
        }
      }
      window.scrollTo(0, 0);
      if (localStorage.getItem("app")) {
        this.$router
          .replace({ name: this.$route.name, query: { app: 1 } })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
@media only screen and (max-width: 768px) {
  .visibileHeader {
    // display: none;
  }
}
</style>
