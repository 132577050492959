var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal)?_c('div',{staticClass:"modal",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal($event)}}},[_c('div',{staticClass:"modal__box modal__big h-full"},[_c('div',{style:(_vm.$route.query.app == 1 ? 'min-height:95vh;' : '')},[(_vm.$route.query.app != 1)?_c('div',{staticClass:"modal__box--header row__spacebetween"},[_c('h2',{staticClass:"text__black m-0"},[_vm._v("Termingespräch")]),_c('div',{on:{"click":_vm.closeModal}},[_c('CloseSvg')],1)]):_vm._e(),_c('div',{staticClass:"modal__body scrollbar h-100",style:(_vm.$route.query.app == 1
						? 'min-height:100%; overflow-x:hidden;'
						: ''),attrs:{"id":"modalBody"}},[_c('div',{staticClass:"w-100 column__center h-100"},[_c('modal-header',{attrs:{"activeIndex":_vm.activeIndex,"steps":_vm.steps},on:{"change":function (index) {
								if (
									_vm.steps[index].done == true ||
									(_vm.steps[index - 1] &&
										_vm.steps[index - 1].done == true)
								) {
									_vm.activeIndex = index;
								}
							}}}),(_vm.activeIndex == 0)?_c('appointment-type',{attrs:{"selectedChatType":_vm.chatType},on:{"change":function (value) {
								if (
									!_vm.phoneNumberRequired ||
									_vm.phoneNumber != ''
								) {
									_vm.steps[_vm.activeIndex].done = true;
								}
								_vm.chatType = value;
							}}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"space-between"}},[_c('div',{staticClass:"searchbox searchbox--small row__start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],attrs:{"type":"text","placeholder":"Vorname *"},domProps:{"value":(_vm.firstName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value},function (event) {
											if (
												_vm.chatType &&
												Object.keys(_vm.chatType)
													.length > 0 &&
												Object.keys(_vm.lastName)
													.length > 0 &&
												Object.keys(_vm.phoneNumber)
													.length > 0
											) {
												_vm.steps[
													_vm.activeIndex
												].done = true;
											}
											if (event.target.value == '') {
												_vm.steps[
													_vm.activeIndex
												].done = false;
											}
										}]}})]),_c('div',{staticClass:"searchbox searchbox--small row__start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],attrs:{"type":"text","placeholder":"Nachname *"},domProps:{"value":(_vm.lastName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value},function (event) {
											if (
												_vm.chatType &&
												Object.keys(_vm.chatType)
													.length > 0 &&
												Object.keys(_vm.firstName)
													.length > 0 &&
												Object.keys(_vm.phoneNumber)
													.length > 0
											) {
												_vm.steps[
													_vm.activeIndex
												].done = true;
											}
											if (event.target.value == '') {
												_vm.steps[
													_vm.activeIndex
												].done = false;
											}
										}]}})])]),(_vm.phoneNumberRequired)?_c('div',{staticClass:"phoneNumberContainer"},[_c('div',{staticClass:"searchbox row__start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"type":"text","placeholder":"Telefonnummer *"},domProps:{"value":(_vm.phoneNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value},function (event) {
											if (
												_vm.chatType &&
												Object.keys(_vm.chatType)
													.length > 0 &&
												Object.keys(_vm.lastName)
													.length > 0 &&
												Object.keys(_vm.firstName)
													.length > 0
											) {
												_vm.steps[
													_vm.activeIndex
												].done = true;
											}
											if (event.target.value == '') {
												_vm.steps[
													_vm.activeIndex
												].done = false;
											}
										}]}})])]):_vm._e()]):(_vm.activeIndex == 1)?_c('appointment-apprenticeship',{attrs:{"selectedApprenticeship":_vm.apprenticeship},on:{"change":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
								_vm.apprenticeship = value;
							}}}):(_vm.activeIndex == 2)?_c('appointment-date',{attrs:{"selectedDate":_vm.date,"selectedApprenticeship":_vm.apprenticeship},on:{"changeDate":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
								_vm.date = value;
							},"changeWorkshop":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
								_vm.workshop = value;
							}}}):(_vm.activeIndex == 3)?_c('appointment-time',{attrs:{"selectedTime":_vm.time,"selectedWorkshop":_vm.date},on:{"change":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
								_vm.time = value;
							}}}):(_vm.activeIndex == 4)?_c('appointment-login',{on:{"change":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
							}}}):(_vm.activeIndex == 5)?_c('appointment-confirmation',{attrs:{"chatType":_vm.chatType,"time":_vm.time,"date":_vm.date,"apprenticeship":_vm.apprenticeship,"done":_vm.steps[_vm.activeIndex].done},on:{"change":function (value) {
								_vm.steps[_vm.activeIndex].done = value;
							}}}):(_vm.activeIndex == 6)?_c('appointment-booked',{on:{"change":function (value) {
								_vm.steps[_vm.activeIndex].done = true;
								_vm.activeIndex = 0;
							}}}):_vm._e()],1),(_vm.$route.query.app == 1)?_c('div',{staticStyle:{"border-top":"#dbdbdb solid 2px","margin":"10px 0"}}):_vm._e(),(_vm.$route.query.app == 1)?_c('div',{staticClass:"modal__footer row_end",style:(_vm.$route.query.app == 1
							? 'background-color:#fff;items-align:flex-end;margin-bottom:50px'
							: '')},[_c('div',{staticClass:"button button--reverse mr-25",on:{"click":_vm.closeModal}},[_c('p',{staticClass:"text__primary text__semibold"},[_vm._v(" Abbrechen ")])]),_c('div',{staticClass:"button mr-10",class:!_vm.steps[_vm.activeIndex].done ? 'button--grey2' : '',on:{"click":_vm.prepareAppointment}},[_c('p',{staticClass:"text__white"},[_vm._v(" "+_vm._s(_vm.activeIndex == 6 ? "Fertig" : _vm.activeIndex == 5 ? "Absenden" : "Weiter")+" ")])])]):_vm._e()])]),(_vm.$route.query.app != 1)?_c('div',{staticClass:"modal__footer row_end",style:(_vm.$route.query.app == 1
					? 'background-color:#fff;items-align:flex-end;margin-bottom:50px'
					: '')},[_c('div',{staticClass:"button button--reverse mr-25",on:{"click":_vm.closeModal}},[_c('p',{staticClass:"text__primary text__semibold"},[_vm._v(" Abbrechen ")])]),_c('div',{staticClass:"button mr-10",class:!_vm.steps[_vm.activeIndex].done ? 'button--grey2' : '',on:{"click":_vm.prepareAppointment}},[_c('p',{staticClass:"text__white"},[_vm._v(" "+_vm._s(_vm.activeIndex == 6 ? "Fertig" : _vm.activeIndex == 5 ? "Absenden" : "Weiter")+" ")])])]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }