export const ENV = {
    VUE_APP_PUSHER_APP_KEY: process.env.VUE_APP_PUSHER_APP_KEY,
    VUE_APP_PUSHER_DEBUG: true,
    VUE_APP_API_BASE:
        process.env.VUE_APP_API_BASE,
    VUE_APP_JITSI_URL:
        process.env.VUE_APP_JITSI_URL,
    VUE_APP_GOOGLE_API_KEY: process.env.VUE_APP_GOOGLE_API_KEY,
    phoneNumberRequired: true,
};
