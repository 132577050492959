<template>
    <div>
        <div class="w-100 mb-10 text-primary" v-if="header">{{ header }}</div>
        <div class="w-100 searchbox searchbox--primary-border row__start">
            <input
                :type="type"
                :value="value"
                :placeholder="placeholder"
                autocomplete
                @input="$emit('input', $event.target.value)"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "text",
        },
        header: {
            type: String,
        },
        value: {},
        placeholder: {
            type: String,
            default: "",
        },
    },
    components: {},
    methods: {},
};
</script>
