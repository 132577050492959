<template>
	<div
		class="company_heading row__spacebetween row__spacebetween--bottom mb-25"
	>
		<img :src="oneCompany.logo" />
		<BackLinks />
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "CompanyHeader",
	data() {
		return {};
	},
	methods: {
		...mapActions([]),
	},
	created() {},
	computed: {
		...mapGetters(["oneCompany"]),
	},
	mounted() {},
};
</script>
