<template>
    <div class="row__spacebetween mb-50 w-100 max-w-700 ">
        <div
            v-for="(item, index) in newSteps"
            :key="index"
            class="appointment-header"
            :class="{ active: index <= activeIndex }"
            @click="$emit('change', index)"
        >
            <div class="progressbar" v-if="index != 5">
                <div :style="`width: ${index < activeIndex ? 100 : 0}%`"></div>
            </div>
            <div class="column__center">
                <div class="index">{{ index + 1 }}</div>
                <div class="name">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    name: "ModalHeader",
    props: ["activeIndex", "steps"],
    emits: ["clickHeader"],
    computed: {
        newSteps() {
            return this.steps.filter((item) => item.name != "Done");
        },
    },
};
</script>
