<template>
    <div class="company__result--row mb-60">
        <HomeCompanyResultCard
            :company="company"
            v-for="(company, index) in filteredCompanies"
            :key="index"
        />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "HomeCompanyResults",
    data() {
        return {};
    },
    props: {
        filteredCompanies: { type: Array, default: () => [] },
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
};
</script>

<style lang="scss">
.company__result--row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}
</style>
