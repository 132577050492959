<template>
	<div class="modal" v-if="showModal" @click.self="closeModal">
		<div class="modal__box modal__big h-full">
			<div :style="$route.query.app == 1 ? 'min-height:95vh;' : ''">
				<div
					v-if="$route.query.app != 1"
					class="modal__box--header row__spacebetween"
				>
					<h2 class="text__black m-0">Termingespräch</h2>
					<div @click="closeModal">
						<CloseSvg />
					</div>
				</div>
				<div
					id="modalBody"
					class="modal__body scrollbar h-100"
					:style="
						$route.query.app == 1
							? 'min-height:100%; overflow-x:hidden;'
							: ''
					"
				>
					<div class="w-100 column__center h-100">
						<modal-header
							:activeIndex="activeIndex"
							:steps="steps"
							@change="
								(index) => {
									if (
										steps[index].done == true ||
										(steps[index - 1] &&
											steps[index - 1].done == true)
									) {
										activeIndex = index;
									}
								}
							"
						/>

						<appointment-type
							:selectedChatType="chatType"
							v-if="activeIndex == 0"
							@change="
								(value) => {
									if (
										!phoneNumberRequired ||
										phoneNumber != ''
									) {
										steps[activeIndex].done = true;
									}
									chatType = value;
								}
							"
						>
							<div
								style="display:flex; flex-wrap:wrap; justify-content: space-between;"
							>
								<div
									class="searchbox searchbox--small row__start"
								>
									<input
										type="text"
										v-model="firstName"
										placeholder="Vorname *"
										@input="
											(event) => {
												if (
													chatType &&
													Object.keys(chatType)
														.length > 0 &&
													Object.keys(lastName)
														.length > 0 &&
													Object.keys(phoneNumber)
														.length > 0
												) {
													steps[
														activeIndex
													].done = true;
												}
												if (event.target.value == '') {
													steps[
														activeIndex
													].done = false;
												}
											}
										"
									/>
								</div>
								<div
									class="searchbox searchbox--small row__start"
								>
									<input
										type="text"
										class=""
										v-model="lastName"
										placeholder="Nachname *"
										@input="
											(event) => {
												if (
													chatType &&
													Object.keys(chatType)
														.length > 0 &&
													Object.keys(firstName)
														.length > 0 &&
													Object.keys(phoneNumber)
														.length > 0
												) {
													steps[
														activeIndex
													].done = true;
												}
												if (event.target.value == '') {
													steps[
														activeIndex
													].done = false;
												}
											}
										"
									/>
								</div>
							</div>
							<div
								v-if="phoneNumberRequired"
								class="phoneNumberContainer"
							>
								<div class="searchbox row__start">
									<input
										type="text"
										v-model="phoneNumber"
										placeholder="Telefonnummer *"
										@input="
											(event) => {
												if (
													chatType &&
													Object.keys(chatType)
														.length > 0 &&
													Object.keys(lastName)
														.length > 0 &&
													Object.keys(firstName)
														.length > 0
												) {
													steps[
														activeIndex
													].done = true;
												}
												if (event.target.value == '') {
													steps[
														activeIndex
													].done = false;
												}
											}
										"
									/>
								</div>
							</div>
						</appointment-type>
						<appointment-apprenticeship
							:selectedApprenticeship="apprenticeship"
							v-else-if="activeIndex == 1"
							@change="
								(value) => {
									steps[activeIndex].done = true;
									apprenticeship = value;
								}
							"
						/>
						<appointment-date
							:selectedDate="date"
							:selectedApprenticeship="apprenticeship"
							v-else-if="activeIndex == 2"
							@changeDate="
								(value) => {
									steps[activeIndex].done = true;
									date = value;
								}
							"
							@changeWorkshop="
								(value) => {
									steps[activeIndex].done = true;
									workshop = value;
								}
							"
						/>
						<appointment-time
							:selectedTime="time"
							:selectedWorkshop="date"
							v-else-if="activeIndex == 3"
							@change="
								(value) => {
									steps[activeIndex].done = true;
									time = value;
								}
							"
						/>
						<appointment-login
							v-else-if="activeIndex == 4"
							@change="
								(value) => {
									steps[activeIndex].done = true;
								}
							"
						/>
						<appointment-confirmation
							:chatType="chatType"
							:time="time"
							:date="date"
							:apprenticeship="apprenticeship"
							:done="steps[activeIndex].done"
							v-else-if="activeIndex == 5"
							@change="
								(value) => {
									steps[activeIndex].done = value;
								}
							"
						/>
						<appointment-booked
							v-else-if="activeIndex == 6"
							@change="
								(value) => {
									steps[activeIndex].done = true;
									activeIndex = 0;
								}
							"
						/>
					</div>
					<div
						v-if="$route.query.app == 1"
						style="border-top:#dbdbdb solid 2px;margin:10px 0;"
					></div>
					<div
						v-if="$route.query.app == 1"
						class="modal__footer row_end"
						:style="
							$route.query.app == 1
								? 'background-color:#fff;items-align:flex-end;margin-bottom:50px'
								: ''
						"
					>
						<div
							class="button button--reverse mr-25"
							@click="closeModal"
						>
							<p class="text__primary text__semibold">
								Abbrechen
							</p>
						</div>
						<div
							class="button mr-10"
							:class="
								!steps[activeIndex].done ? 'button--grey2' : ''
							"
							@click="prepareAppointment"
						>
							<p class="text__white">
								{{
									activeIndex == 6
										? "Fertig"
										: activeIndex == 5
										? "Absenden"
										: "Weiter"
								}}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="$route.query.app != 1"
				class="modal__footer row_end"
				:style="
					$route.query.app == 1
						? 'background-color:#fff;items-align:flex-end;margin-bottom:50px'
						: ''
				"
			>
				<div class="button button--reverse mr-25" @click="closeModal">
					<p class="text__primary text__semibold">
						Abbrechen
					</p>
				</div>
				<div
					class="button mr-10"
					:class="!steps[activeIndex].done ? 'button--grey2' : ''"
					@click="prepareAppointment"
				>
					<p class="text__white">
						{{
							activeIndex == 6
								? "Fertig"
								: activeIndex == 5
								? "Absenden"
								: "Weiter"
						}}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import ClickOutside from "vue-click-outside";
	import AppointmentType from "./AppointmentType.vue";
	import ModalHeader from "./ModalHeader.vue";
	import AppointmentApprenticeship from "./AppointmentApprenticeship.vue";
	import AppointmentDate from "./AppointmentDate.vue";
	import AppointmentTime from "./AppointmentTime.vue";
	import AppointmentLogin from "./AppointmentLogin.vue";
	import AppointmentConfirmation from "./AppointmentConfirmation.vue";
	import AppointmentBooked from "./AppointmentBooked.vue";
	export default {
		components: {
			AppointmentType,
			ModalHeader,
			AppointmentApprenticeship,
			AppointmentDate,
			AppointmentTime,
			AppointmentLogin,
			AppointmentBooked,
			AppointmentConfirmation,
		},
		name: "AppointmentModal",
		props: ["company_id", "showModal"],
		data() {
			return {
				activeIndex: 0,
				steps: [
					{ name: "Art", done: false },
					{ name: "Beruf", done: false, value: {} },
					{ name: "Datum", done: false, value: {} },
					{ name: "Zeit", done: false, value: {} },
					{ name: "Anmeldung", done: false, value: {} },
					{ name: "Bestätigung", done: false, value: {} },
					{ name: "Done", done: true },
				],
				appointment: {},
				chatType: {},
				apprenticeship: {},
				date: {},
				time: {},
				phoneNumber: "",
				firstName: "",
				lastName: "",
				allTimes: [],
				contactAppointments: [],
				isLoading: false,
				errorMessage: "",
				workshop: {},
			};
		},
		methods: {
			...mapActions(["bookAppointment", "openAppointmentBooking"]),
			prepareBookAppointment() {
				var appointment = {
					chatTypeId: this.chatType.id,
					day: this.date,
					time: this.time,
					contactId: this.apprenticeship.contactId,
					companyId: this.apprenticeship.companyId,
					apprenticeshipId: this.apprenticeship.id,
					phoneNumber: this.phoneNumber,
					firstName: this.firstName,
					lastName: this.lastName,
				};
				this.bookAppointment(appointment);
			},
			async prepareAppointment() {
				if (this.steps[this.activeIndex].done == false) {
					return;
				}
				if (this.activeIndex == 1) {
					await this.openAppointmentBooking({
						company_id: this.company_id,
						apprenticeship_id: this.apprenticeship.id,
					});
				}
				if (this.activeIndex == 5) {
					this.prepareBookAppointment();
				}
				if (this.activeIndex != 6) {
					this.activeIndex += 1;

					return;
				} else {
					this.closeModal();
					return;
				}
			},

			closeModal() {
				this.steps = [
					{ name: "Art", done: false },
					{ name: "Beruf", done: false, value: {} },
					{ name: "Datum", done: false, value: {} },
					{ name: "Zeit", done: false, value: {} },
					{ name: "Anmeldung", done: false, value: {} },
					{ name: "Bestätigung", done: false, value: {} },
				];
				this.chatType = {};
				this.apprenticeship = {};
				this.date = {};
				this.time = {};
				this.phoneNumber = "";
				this.allTimes = [];
				this.contactAppointments = [];
				this.activeIndex = 0;
				this.$emit("close");
			},
		},
		created() {},
		computed: {
			...mapGetters(["companyChatTypes", "companyContacts"]),
			phoneNumberRequired() {
				return window.phoneNumberRequired;
			},
		},
		mounted() {},
		directives: {
			ClickOutside,
		},
	};
</script>
<style lang="scss">
	.modal .modal__box {
		&.modal__big {
			height: 100vh;
			max-height: 850px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-radius: 0px;
		}
	}

	.searchbox--small {
		max-width: 300px;
		margin-bottom: 10px;
	}
	@media only screen and (max-width: 600px) {
		.searchbox--small {
			max-width: 600px;
		}

		.phoneNumberContainer {
			margin-bottom: 20px;
		}
	}

	#modalBody {
		max-height: calc(97vh - 80px);
	}
</style>
