var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar row__spacebetween main__padding"},[_c('div',{staticClass:"row__spacebetween w-100 cursor-pointer"},[_c('a',{staticClass:"logo--svg",on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('LogoSvg')],1),_c('div',{staticClass:"row__spacebetween navbar--row m-15 for__navbar--big"},_vm._l((_vm.navbarRoutes),function(route){return _c('a',{key:route.id,staticClass:"navbar--link cursor-pointer",on:{"click":function () {
						route.link != _vm.$route.name
							? _vm.$router.push({ name: route.link })
							: '';
					}}},[_vm._v(_vm._s(route.name))])}),0),_c('a',{staticClass:"contact-button row__center for__navbar--big",on:{"click":function($event){return _vm.$router.push({ name: 'Contact' })}}},[_c('p',{staticClass:"text__white small"},[_vm._v("Kontakt")])]),_c('div',{staticClass:"for__navbar--small pointer",on:{"click":function($event){_vm.navbarActive = !_vm.navbarActive}}},[_c('BurgerMenu',{attrs:{"active":_vm.navbarActive}})],1)]),_c('div',{staticClass:"navbar__dropdown",class:{ 'navbar__dropdown--active': _vm.navbarActive }},_vm._l((_vm.navbarRoutes),function(route){return _c('a',{key:route.id,on:{"click":function () {
					route.link != _vm.$route.name ? _vm.$router.push({ name: route.link }) : '';

					_vm.navbarActive = false;
				}}},[_vm._v(_vm._s(route.name))])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }